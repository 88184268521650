import React from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

import styles from "./GetInTouch.module.scss";
import SectionBar from "../../SectionBar/SectionBar";

const propTypes = {
	/** Data parsed from parent index component's graphql query */
	data: PropTypes.string.isRequired,
};

export default function GetInTouch({ data }) {
	const [sectionRef, inView] = useInView({
		threshold: 0.4,
		triggerOnce: true,
	});

	return (
		<section ref={sectionRef} className={styles.section__wrapper}>
			<SectionBar isInView={inView} />

			<div className={styles.content__wrapper}>
				<span className={styles.content__label}>&nbsp;</span>
				<motion.div
					className={styles.content__htmlContent}
					dangerouslySetInnerHTML={{ __html: data }}
					initial={false}
					animate={{
						opacity: inView ? 1 : 0,
						y: inView ? 0 : 10,
					}}
					transition={{
						duration: 2.5,
						delay: 0.1,
					}}
				/>
			</div>
		</section>
	);
}

GetInTouch.propTypes = propTypes;
