import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import styles from "./SectionBar.module.scss";

const propTypes = {
	isInView: PropTypes.bool,
};

const defaultProps = {
	isInView: undefined,
};

function SectionBar({ isInView }) {
	return (
		<motion.div
			className={styles.bar}
			initial={{
				clipPath:
					isInView === undefined
						? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
						: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
			}}
			animate={{
				clipPath: isInView
					? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
					: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
			}}
			transition={{
				clipPath: {
					duration: 0.5,
				},
			}}
		/>
	);
}

SectionBar.propTypes = propTypes;
SectionBar.defaultProps = defaultProps;

export default SectionBar;
