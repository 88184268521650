import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import uniqid from "uniqid";

import styles from "./About.module.scss";
import SectionBar from "../../SectionBar/SectionBar";

const propTypes = {
	inView: PropTypes.bool,
	data: PropTypes.string.isRequired,
};

const defaultProps = {
	inView: false,
};

export default function About({ data, inView }) {
	return (
		<section className={styles.section__wrapper}>
			<SectionBar isInView={inView} />

			<div className={styles.text__wrapper}>
				<div className={styles.text__header}>About</div>
				{data && (
					<div className={styles.text__body}>
						{data.split(" ").map((item, idx) => (
							<React.Fragment key={uniqid()}>
								<motion.h1
									className={styles.text__h1}
									initial={{ opacity: 0 }}
									animate={{ opacity: inView ? 1 : 0 }}
									transition={{
										delay: 0.025 * (idx + 1),
										duration: 2.5,
									}}
								>
									{item}
								</motion.h1>
								{idx < data.split(" ").length && " "}
							</React.Fragment>
						))}
					</div>
				)}
			</div>
		</section>
	);
}

About.propTypes = propTypes;
About.defaultProps = defaultProps;
