import React from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import WorksListItem from "./WorksListItem/WorksListItem";

import styles from "./WorksList.module.scss";
import SectionBar from "../../SectionBar/SectionBar";

const propTypes = {
	/** Data containing work items parsed from parent index graphql query */
	data: PropTypes.array.isRequired,
};

export default function WorksList({ data }) {
	const { worksData, workOrderListData } = data;
	const [sectionRef, inView] = useInView({
		threshold: 0.25,
		triggerOnce: true,
	});

	const sortedWorksData = [];
	for (const item of workOrderListData) {
		const matchingWorkItem = worksData.filter(({ node: wd }) => {
			return wd.childMarkdownRemark.fields.slug.replace(/\//g, "") === item;
		})?.[0];

		sortedWorksData.push(matchingWorkItem);
	}

	return (
		<section ref={sectionRef} className={styles.section__wrapper}>
			<SectionBar isInView={inView} />

			<div className={styles.content__wrapperMain}>
				<div className={styles.content__sectionLabel}>RECENT WORKS</div>
				<div className={styles.content__wrapperContent}>
					{sortedWorksData.map((item, idx) => (
						<WorksListItem
							key={item.node.childMarkdownRemark.fields.slug}
							sectionIsInView={inView}
							idx={idx}
							to={`/work${item.node.childMarkdownRemark.fields.slug}`}
							data={item.node.childMarkdownRemark}
						/>
					))}
				</div>
			</div>
		</section>
	);
}

WorksList.propTypes = propTypes;
