import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import styles from "./PersistentThumbnail.module.scss";

const propTypes = {
	sectionIsInView: PropTypes.bool.isRequired,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
};

function PersistentThumbnail({ sectionIsInView, src, alt }) {
	return (
		<section className={styles.persistentThumbnail__wrapper}>
			<motion.img
				src={src}
				alt={alt}
				className={styles.persistentThumbnail__img}
				animate={{
					clipPath: sectionIsInView
						? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
						: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
					scale: sectionIsInView ? 1 : 1.25,
				}}
				transition={{
					clipPath: {
						ease: "circOut",
						duration: 0.5,
					},
					scale: {
						ease: "circOut",
						duration: 0.35,
					},
				}}
			/>
		</section>
	);
}

PersistentThumbnail.propTypes = propTypes;

export default PersistentThumbnail;
