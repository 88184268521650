import React, { useContext } from "react";
import Moment from "react-moment";
import "moment-timezone";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { formatLineBreaks, headerTransitions } from "../../../utils";
import { PreloaderContext } from "../../Preloader/Preloader";

import styles from "./Intro.module.scss";

const propTypes = {
	/** Data parsed from parent index graphql query */
	data: PropTypes.string.isRequired,
};

export default function Intro({ data }) {
	const { isPreloaderComplete } = useContext(PreloaderContext);

	return (
		<section className={styles.section__wrapper}>
			<motion.div className={styles.timezone__wrapper} {...headerTransitions(isPreloaderComplete)}>
				<p className={styles.timezone__header}>GMT +8 Hours</p>
				<p className={styles.timezone__body}>
					<Moment interval={1000} tz="Asia/Singapore" format="dddd, D MMMM – hh:mma" />
				</p>
			</motion.div>

			<div className={styles.section__content}>
				{formatLineBreaks(data).map((item, idx) => (
					<div key={item} className={styles.section__copyLiner}>
						<motion.h1
							initial={{ y: "250%" }}
							animate={{ y: isPreloaderComplete && 0 }}
							transition={{
								delay: 0.14 * (idx + 1),
								duration: 1.2,
								ease: [0.33, 1, 0.68, 1],
							}}
						>
							{item}
						</motion.h1>
					</div>
				))}
			</div>
		</section>
	);
}

Intro.propTypes = propTypes;
