import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { formatLineBreaks } from "../../../utils";

import styles from "./Expertise.module.scss";

const propTypes = {
	inView: PropTypes.bool,
	/** Data parsed from parent index component's graphql query */
	data: PropTypes.object.isRequired,
};

const defaultProps = {
	inView: false,
};

const initial = () => ({
	clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
});

const animate = (inViewFlag) => ({
	clipPath: inViewFlag
		? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
		: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
});

const transition = (delayVal = 0) => ({
	delay: delayVal,
	duration: 2,
});

export default function Expertise({ data, inView }) {
	const { skillSet, frontEnd } = data;

	return (
		<section className={styles.section__wrapper}>
			<div className={styles.section__label}>Expertise</div>

			<section className={styles.content__wrapper}>
				<motion.span
					className={styles.content__bar}
					initial={{ clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" }}
					animate={{
						clipPath: inView
							? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
							: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
					}}
					transition={{
						delay: 0.3,
						duration: 1.5,
					}}
				/>

				<section className={styles.subContent__wrapperMain}>
					<div className={styles.subContent__label}>Skillset</div>
					<motion.div
						className={styles.subContent__wrapperContent}
						initial={initial()}
						animate={animate(inView)}
						transition={transition(0.9)}
					>
						{formatLineBreaks(skillSet).map((item) => (
							<div key={item} className={styles.subContent__item}>
								{item}
							</div>
						))}
					</motion.div>
				</section>

				<section className={styles.subContent__wrapperMain}>
					<div className={styles.subContent__label}>Front-end Development</div>
					<motion.div
						className={styles.subContent__wrapperContent}
						initial={initial()}
						animate={animate(inView)}
						transition={transition(1.5)}
					>
						{formatLineBreaks(frontEnd).map((item) => (
							<div key={item} className={styles.subContent__item}>
								{item}
							</div>
						))}
					</motion.div>
				</section>
			</section>
		</section>
	);
}

Expertise.propTypes = propTypes;
Expertise.defaultProps = defaultProps;
