import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import uniqid from "uniqid";

import Intro from "../components/Index/Intro/Intro";
import WorksList from "../components/Index/WorksList/WorksList";
import About from "../components/Index/About/About";
import Expertise from "../components/Index/Expertise/Expertise";
import GetInTouch from "../components/Index/GetInTouch/GetInTouch";

const indexPropTypes = {
	/** Data return from graphql query */
	data: PropTypes.object.isRequired,
};

const aboutSectionObserverPropTypes = {
	/** Data return from graphql query */
	style: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

/**
 * attached intersection observer to components
 */
function AboutSectionObserver({ style, children }) {
	const [sectionRef, inView] = useInView({
		threshold: 0.35,
		triggerOnce: true,
	});

	return (
		<section ref={sectionRef} style={style}>
			{children.map((child) => (
				<React.Fragment key={uniqid()}>{React.cloneElement(child, { inView })}</React.Fragment>
			))}
		</section>
	);
}

export default class IndexPage extends React.PureComponent {
	render() {
		const {
			data: { indexContent, works, workOrderList },
		} = this.props;
		const indexContentData = get(indexContent, "childMarkdownRemark.frontmatter");
		const worksData = get(works, "edges");
		const workOrderListData = get(workOrderList, "frontmatter.work_order_list_widget");

		return (
			<>
				<Intro data={indexContentData.intro} />
				<AboutSectionObserver style={{ minHeight: "100%" }}>
					<About data={indexContentData.about} />
					<Expertise
						data={{
							skillSet: indexContentData.expertiseSkillSet,
							frontEnd: indexContentData.expertiseFrontEnd,
						}}
					/>
				</AboutSectionObserver>
				<WorksList data={{ worksData, workOrderListData }} />
				<GetInTouch data={indexContentData.getInTouch} />
			</>
		);
	}
}

export const query = graphql`
	query {
		indexContent: file(name: { eq: "index" }) {
			childMarkdownRemark {
				frontmatter {
					intro
					about
					expertiseFrontEnd
					expertiseSkillSet
					getInTouch
				}
			}
		}

		works: allFile(filter: { relativeDirectory: { eq: "works" } }) {
			edges {
				node {
					id
					name
					relativeDirectory
					childMarkdownRemark {
						id
						frontmatter {
							title
							heroVisual {
								childImageSharp {
									fluid(maxWidth: 2000, quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
								extension
							}
							role
						}
						fields {
							slug
						}
					}
				}
			}
		}

		workOrderList: markdownRemark(fields: { slug: { eq: "/work_order/" } }) {
			frontmatter {
				work_order_list_widget
			}
		}
	}
`;

IndexPage.propTypes = indexPropTypes;
AboutSectionObserver.propTypes = aboutSectionObserverPropTypes;
