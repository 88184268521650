import React, { useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import MediaQuery from "react-responsive";
import { MouseEventsContext } from "../../../ContextWrappers/ContextWrappers";
import useHasMounted from "../../../../hooks/useHasMounted";

import { leadingZero, formatLineBreaks } from "../../../../utils";

import PersistentThumbnail from "./PersistentThumbnail/PersistentThumbnail";

import styles from "./WorksListItem.module.scss";

const propTypes = {
	sectionIsInView: PropTypes.bool.isRequired,
	idx: PropTypes.number.isRequired,
	to: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
};

function WorksListItem({ sectionIsInView, idx, to, data }) {
	const [hasRendered, setHasRendered] = useState(false);
	const [isHovered, setIsHovered] = useState(null);
	const mainElRef = useRef();
	const thumbnailElRef = useRef();
	const roleArr = formatLineBreaks(data.frontmatter.role);
	const [hasTitleAnimated, setHasTitleAnimated] = useState(false);

	const mousePosContext = useContext(MouseEventsContext);
	const hasMounted = useHasMounted();

	if (!hasMounted) return null;

	const calcPosition = () => {
		let calcTop = 0;
		let calcLeft = 0;
		const viewportHeight = window.innerHeight;
		const viewportWidth = window.innerWidth;

		if (thumbnailElRef.current && mousePosContext) {
			const { x, y } = mousePosContext;
			const thumbnailHeight = thumbnailElRef.current.getBoundingClientRect().height;
			const thumbnailWidth = thumbnailElRef.current.getBoundingClientRect().width;
			const offsetClientY = y - thumbnailHeight * 0.2;

			let offsetY = 0;
			let offsetX = 0;

			// prevent thumbnails from appearing beyond the viewport
			if (x + thumbnailWidth > viewportWidth && viewportWidth !== 0) {
				offsetX = (x + thumbnailWidth - viewportWidth) * 1.05;
			}
			if (offsetClientY + thumbnailHeight > viewportHeight && viewportHeight !== 0) {
				offsetY = (offsetClientY + thumbnailHeight - viewportHeight) * 1.05;
			}
			if (offsetClientY < 0) {
				offsetY = offsetClientY;
			}

			calcTop = offsetClientY - offsetY;
			calcLeft = x - offsetX;
		}

		return {
			top: calcTop,
			left: calcLeft,
		};
	};

	return (
		<Link
			ref={mainElRef}
			key={to}
			to={to}
			className={styles.wrapper__main}
			onMouseOver={() => {
				if (!isHovered) setIsHovered(true);
				if (!hasRendered) setHasRendered(true);
			}}
			onMouseLeave={() => setIsHovered(false)}
			onFocus={() => {}}
		>
			<div className={styles.item__index}>{leadingZero(idx + 1)}</div>

			<MediaQuery maxWidth={1050}>
				<PersistentThumbnail
					sectionIsInView={sectionIsInView}
					src={data.frontmatter.heroVisual.publicURL}
					alt={data.frontmatter.title}
				/>
			</MediaQuery>

			<div className={styles.wrapper__blendMode}>
				<motion.div
					className={styles.item__titleWrapper}
					initial={{ clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" }}
					animate={{
						clipPath: sectionIsInView
							? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
							: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
					}}
					transition={{
						clipPath: {
							duration: 0.8,
							delay: 0.2 * (idx + 1),
						},
					}}
					onAnimationComplete={() => setHasTitleAnimated(true)}
				>
					<h2 className={styles.item__titleTextPrimary}>{data.frontmatter.title}</h2>
					<h2 className={styles.item__titleTextSecondary}>{data.frontmatter.title}</h2>

					<motion.h2
						className={styles.item__titleTextSecondary}
						initial={false}
						animate={{
							clipPath: isHovered
								? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
								: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
						}}
						transition={{
							clipPath: {
								duration: 0.5,
							},
						}}
					>
						{data.frontmatter.title}
					</motion.h2>
				</motion.div>

				<motion.div
					className={styles.item__roles}
					initial={{ clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" }}
					animate={{
						clipPath: sectionIsInView
							? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
							: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
					}}
					transition={{
						clipPath: {
							duration: 4,
							delay: 0.3 * (idx + 1),
						},
					}}
				>
					{roleArr.map((roleItem, roleIdx) => {
						return (
							<React.Fragment key={roleItem}>
								<span>{roleItem}</span>
								{roleIdx < roleArr.length - 1 && <span> / </span>}
							</React.Fragment>
						);
					})}
				</motion.div>
			</div>

			<MediaQuery minWidth={1050}>
				<motion.div
					ref={thumbnailElRef}
					className={`${styles.thumbnail__wrapper} ${
						!hasRendered ? styles["thumbnail__wrapper--hideFromView"] : ""
					}`}
					initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
					animate={{
						clipPath:
							isHovered && hasTitleAnimated
								? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
								: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
					}}
					transition={{
						clipPath: {
							ease: "circOut",
							duration: 0.65,
						},
					}}
					style={{
						top: `${calcPosition().top}px`,
						left: `${calcPosition().left}px`,
					}}
				>
					<motion.img
						className={styles.thumbnail__img}
						src={data.frontmatter.heroVisual.publicURL}
						alt={data.frontmatter.title}
						initial={false}
						animate={{
							scale: isHovered ? 1 : 1.5,
						}}
						transition={{
							scale: {
								ease: "circOut",
								duration: 0.9,
								delay: 0.1,
							},
						}}
					/>
				</motion.div>
			</MediaQuery>

			<motion.div
				className={styles.wrapper__bar}
				initial={{ clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" }}
				animate={{
					clipPath: sectionIsInView
						? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
						: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
				}}
				transition={{
					clipPath: {
						ease: "circOut",
						delay: 0.3 * (idx + 1),
						duration: 0.45,
					},
				}}
			/>
		</Link>
	);
}

WorksListItem.propTypes = propTypes;

export default WorksListItem;
